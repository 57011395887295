// import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleTagManager } from "@next/third-parties/google";
import HomePage from "./13-university-classic";
import BackToTop from "./backToTop";

export default function Home() {
  return (
    <>
      <main className="">
      {/* <GoogleOAuthProvider clientId="77524109234-cr3bf2782g8b3iikljs1kabk3155jlrl.apps.googleusercontent.com"> */}
        <HomePage />
        <BackToTop />
        <GoogleTagManager gtmId="GTM-NCWP76SF" />
        <GoogleTagManager gtmId="AW-715107438" />
        <GoogleTagManager gtmId="GT-MBT2FMB" />
        <GoogleTagManager gtmId="G-BNS91FYXZ4" />
        {/* </GoogleOAuthProvider> */}
      </main>
    </>
  );
}
